.ant-menu-submenu-title,
.ant-menu-item,
.ant-menu-submenu,
.ant-menu-horizontal > .ant-menu-submenu {
  display: 'flex' !important;
  align-items: 'center' !important;
}

.ant-menu-item a {
  text-decoration: none !important;
}

.modal-dialog {
  height: calc(100% - 190px);
  position: 'absolute';
  width: 100%;
  padding: 20px;
  margin: 0;
  margin-top: 130px;
  box-shadow: none;
}

.modal-header {
  background-color: '#EEEEEE';
  font-size: 16px;
}

.modal-footer {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-header::before,
.modal-header::after,
.modal-footer::before,
.modal-footer::after {
  content: none !important;
}

.modal-content {
  height: 100%;
  box-shadow: none;
}

.react-data-table {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
  width: 100%;
  border: none;
  overflow-x: auto;
}

.rt-tr .rt-td:first-child {
  padding-left: 13px !important;
}

.react-data-table {
  max-height: 100%;
}

.react-data-table .rt-td {
  font-family: 'Roboto';
  color: 'red';
  font-weight: 300;
  font-size: 13px;
  position: relative;
}

.rt-tr-group .rt-tbody {
  overflow: scroll;
  height: 100vh;
  max-height: 40px;
}

.rt-td > div {
  align-items: center;
  display: flex;
}

.dropzone {
  padding: 20px;
}

.modal-backdrop.in {
  opacity: 0;
}

.modal-body {
  // min-height: 500px;
}

.swal2-title {
  font-size: 14px !important;
  font-family: 'Roboto';
  font-weight: 300;
}

.add-litigation-modal .modal-content,
.add-other-litigation-modal .modal-content {
  margin-top: 20px;
  height: 800px;
}

.add-litigation-div {
  // height: 570px;
}

.datepicker .switch {
  display: table-cell;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-menu-item-icon {
  font-size: 18px;
}

.add-litigation-modal-detail-page .modal-content {
  margin-top: 0 !important;
}

.rt-table .rt-tbody .rt-td:first-child {
  font-weight: bold;
}

.MuiPaper-root .card {
  min-height: 400px;
  max-height: 400px;
}

.MuiPaper-root .rt-table {
  min-height: 350px;
  max-height: 350px;
}

.MuiPaper-root .content {
  min-height: 350px;
  max-height: 350px;
}
