.MuiDropzoneArea-textContainer {
  display: none;
}

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.MuiDropzonePreviewList-image {
  height: auto !important;
  width: 100% !important;
}

.MuiCalendarPicker-root .MuiPickersDay-root,
.MuiPickersDay-today {
  font-size: 12px;
}

.css-7hd3a8-MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #d81b60 !important;
}

.MuiInputLabel-standard {
  font-size: 16px !important;
}

#mui-27-label {
  font-size: 16px !important;
}

.form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiList-root li {
  font-size: 16px;
  display: block;
  padding: 10px 15px;
}

.modal-content {
  height: auto !important;
}

.add-litigation-div .MuiInput-root {
}

.MuiIconButton-edgeEnd {
  margin-right: auto !important;
}

.add-litigation-detail-modal > .modal,
.add-litigation-detail-modal {
  margin-top: 0 !important;
  position: relative !important;
}

.add-litigation-detail-modal .modal-dialog {
  margin-top: 0 !important;
}

.add-litigation-detail-modal {
  margin-top: 0 !important;
}

.modal-backdrop.in {
  display: none;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: #d81b60 !important;
  opacity: 0.5;
}
