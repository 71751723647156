.MuiDropzoneArea-textContainer {
  display: none;
}

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.MuiDropzonePreviewList-image {
  height: auto !important;
  width: 100% !important;
}

.MuiCalendarPicker-root .MuiPickersDay-root,
.MuiPickersDay-today {
  font-size: 12px;
}

.css-7hd3a8-MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #d81b60 !important;
}

.MuiInputLabel-standard {
  font-size: 16px !important;
}

#mui-27-label {
  font-size: 16px !important;
}

.form-group {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiList-root li {
  font-size: 16px;
  display: block;
  padding: 10px 15px;
}

.modal-content {
  height: auto !important;
}

.add-litigation-div .MuiInput-root {
}

.MuiIconButton-edgeEnd {
  margin-right: auto !important;
}

.css-yk16xz-control {
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  outline: none !important;
}

.css-yk16xz-control:hover, .css-yk16xz-control:active, .css-yk16xz-control:focus-visible, .css-yk16xz-control:visited {
  outline: none !important;
}

.css-2b097c-container:hover, .css-2b097c-container:active, .css-2b097c-container:focus-visible, .css-2b097c-container:visited {
  outline: none !important;
}

/* .modal-backdrop {
  display: none;
}

.modal-dialog {
  margin-top: 0 !important;
}

.modal {
  position: relative !important;
} */
