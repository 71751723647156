.form-group * {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-group .css-2b097c-container {
  border-bottom: 1px solid black !important;
}

.css-g1d714-ValueContainer {
  padding: 0 !important;
}

.css-1wa3eu0-placeholder,
.css-1uccc91-singleValue {
  font-size: 16px !important;
}

.css-2b097c-container {
  position: relative;
  top: -13px;
}

.form-group {
  padding-left: 14px;
  padding-right: 14px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}
