.react-data-table.rthfc .rt-th.rthfc-th-fixed-right-first,
.react-data-table.rthfc .rt-td.rthfc-td-fixed-right-first {
  border-left: none;
}

.withDetailsCell {
  .rt-thead .rt-th:last-child {
    text-align: center;
  }
}

.withoutActionsCell {
  .rt-thead .rt-th:last-child {
    text-align: left;
  }
}

.rt-thead * {
  font-size: 16px;
}

.ticketSelectCell {
  .rt-td {
    overflow: initial;
  }
}

.viewAllTextCell {
  .rt-td {
    white-space: normal;
  }
}
