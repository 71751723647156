.MuiDropzoneArea-textContainer {
  display: none;
}

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.MuiDropzonePreviewList-image {
  height: auto !important;
  width: 100% !important;
}

.MuiCalendarPicker-root .MuiPickersDay-root,
.MuiPickersDay-today {
  font-size: 12px;
}

.css-7hd3a8-MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #d81b60 !important;
}

.rdw-editor-wrapper {
  border: 1px solid #ddd !important;
  padding: 10px;
}
