body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-data-table {
  width: 100%;
}

.rt-resizable-header {
  background-color: #eeeeee !important;
}

.rt-resizable-header-content {
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  text-align: left;
  padding: 6px;
}

.rt-table {
  max-height: calc(80vh - 115px);
}

.add-litigation-modal .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
}

.add-litigation-modal-detail-page {
  margin-top: 0 !important;
}

#mui-4-helper-text,
#mui-5-helper-text {
  display: none;
}
