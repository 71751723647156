.dropzone {
  width: 100%;
  height: 73px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: ease 0.1s;
  background-color: #eee;
}

.dropzone:hover {
  border: 2px dashed black;
}

.dropzone input[type='file'] {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
