.MuiDropzoneArea-textContainer {
  display: none;
}

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.MuiDropzonePreviewList-image {
  height: auto !important;
  width: 100% !important;
}

.MuiCalendarPicker-root .MuiPickersDay-root,
.MuiPickersDay-today {
  font-size: 12px;
}

.MuiDialog-root {
  z-index: 99999999 !important;
}

.MuiDialogContentText-root {
  font-size: 14px !important;
}

.MuiButton-text {
  font-size: 14px !important;
}

.MuiDialogTitle-root {
  font-size: 20px !important;
}
