.MuiDropzoneArea-textContainer {
  display: none;
}

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.MuiDropzonePreviewList-image {
  height: auto !important;
  width: 100% !important;
}

.account-div .MuiInput-root {
  margin: 20px 0;
}
