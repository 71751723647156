.errorPage {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 50px;
  }

  p {
    font-size: 24px;
  }
}
