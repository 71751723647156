#nesto-listbox *,
#nesto1-listbox *,
#nesto2-listbox *,
#nesto3-listbox *,
#nesto4-listbox *,
#nesto5-listbox *,
#nesto6-listbox *,
#nesto7-listbox *,
#nesto8-listbox *,
#nesto9-listbox * {
  font-size: 16px !important;
}

.nesto-listbox * {
  font-size: 16px !important;
}
